<template>
	<figure class="quizes-editor-content__chapters--parent">
		<v-row>
			<v-col cols="8">
				<span class="mr-4">Question #{{ questionId }}</span>
				<span class="mr-4"
					>Last Edited:
					{{
						`${Math.ceil(
							Number(new Date() - new Date(questionUpdateDate)) /
								1000 /
								60 /
								60 /
								24
						)} days ago`
					}}</span
				>
				<span class="mr-4"
					>Date Created:
					{{
						`${new Date(
							questionCreatedDate.slice(0, 10)
						).toLocaleDateString()}`
					}}</span
				>
			</v-col>
			<v-col cols="4">
				<div class="d-flex flex-row justify-end">
					<span
						class="ml-4"
						@click="$emit('deleteQuestion', questionId)"
						>Delete</span
					>
					<span
						class="ml-4"
						@click="$emit('duplicateQuestion', questionId)"
						>Duplicate</span
					>
					<span
						class="ml-4"
						@click="$emit('editQuestion', questionId)"
						>Edit</span
					>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>{{ questionContent }} </v-col>
		</v-row>
	</figure>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "QuestionBankQuestionContent",
	props: ["question"],
	computed: {
		...mapGetters(["isLoading"]),
		questionContent() {
			if (!!this.question?.questions === false) return;
			return this.question?.questions[0].content;
		},
		questionId() {
			return this.question.id;
		},
		questionUpdateDate() {
			return this.question.updated_at;
		},
		questionCreatedDate() {
			return this.question.created_at;
		}
	},
	data() {
		return {};
	}
};
</script>

<style></style>
